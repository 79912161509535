<template>
  <div class="home">
    <ExamImageStart :idClassroom="$route.params.idClassroom"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ExamImageStart from '@/components/exam/examImage/ExamImageStart'

export default {
  name: 'ExamImageStartView',
  components: {
    ExamImageStart
  }
}
</script>
